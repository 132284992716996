import LanguageIdentifier from "components/languageIdentifier";
import Layout from "components/layout";
import React from "react";
import Seo from "components/seo";
import ToolFaq from "components/QualityTools/ToolFaq";
import { LanguageDetectorToolFaqData } from "components/FAQ/config";
import BannerSection from "components/PostTranslation/BannerSection";
import BannerImage from "assets/language_banner.png"

const index = () => {

  const BannerData = [
    {
      text: "<span class='font-bold'>Detect Multilingual Texts:</span> Pinpoint every language in your text, along with a clear percentage breakdown.",
    },
    {
      text: "<span class='font-bold'>Get Instant Translation:</span> Get the meaning of the content with an automatic sample translation of the first 200 words.",
    },
    {
      text: "<span class='font-bold'>Free, No Sign-up: </span> Access full features immediately. No cost, no registration.",
    },
    {
      text: "<span class='font-bold'>Text & URL Input: </span>  Paste text directly or enter a web page link. The tool extracts the page content and identifies its language(s).",
    },
    {
      text: "<span class='font-bold'>Save Time & Make Informed Decisions: </span> Quickly assess content to plan next steps, whether it's for business, localization, or content review..",
    },
    
  ];

  return (
    <Layout>
      <Seo
        title="Detect Language - Instant Language Detector"
        description="Instantly detect and identify the languages used in a text. Use our language detection tool to quickly find out what languages you're dealing with. Try it for free."
        slug="/tools/language-detector"
      />
      <LanguageIdentifier />
      <div className="bg-white ">
          <div className="max-w-7xl mx-auto p-4">
            <BannerSection
              title="Free AI Language Detector:<br/>Identify and Translate Multilingual Texts for Free"
              description="The Tomedes Language Detector does more than just identify the language - it gives you actionable insights. Instantly detect one or more languages in your text and see exactly how your content is split, with percentages for each language (for example, 30% Italian, 30% Chinese, 20% French, 20% English).
              <br /><br/>
              Plus, this free AI Language Detector helps you move from detection to understanding. The tool automatically translates the first 200 words into your browser’s language, giving you instant context on the text's meaning, without any extra steps.
              "

              BannerData={BannerData}
              BannerImage={BannerImage}
              text="Whether you're working with plain text or an entire web page, this tool can quickly identify what language(s) your text is in and provide clarity.<br/><br/>

               Perfect for businesses, researchers, and content reviewers, this tool helps you quickly assess multilingual content, whether from a document or a website, and take the next step with confidence."
             isTranslatoir={true}
           />
          </div>
        </div>
      <ToolFaq QaToolFaqData={LanguageDetectorToolFaqData} />
    </Layout>
  );
};

export default index;
